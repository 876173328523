export const getHoursPhrase = hours => {
  const parsed = String(hours)
  const lastDigit = parsed[parsed.length - 1]
  if (lastDigit > 1 && lastDigit < 5) {
    return "godziny lekcyjne"
  }
  return "godzin lekcyjnych"
}

const mapMonthToName = {
  "01": "Stycznia",
  "02": "Lutego",
  "03": "Marca",
  "04": "Kwietnia",
  "05": "Maja",
  "06": "Czerwca",
  "07": "Lipca",
  "08": "Sierpnia",
  "09": "Września",
  10: "Października",
  11: "Listopada",
  12: "Grudnia",
}

export const getMonthName = month => mapMonthToName[month]

const mapDayToName = {
  mo: "poniedziałek",
  tu: "wtorek",
  we: "środa",
  th: "czwartek",
  fr: "piątek",
  sa: "sobota",
  su: "niedziela",
}

export const getDayName = day => mapDayToName[day]

export const serialize = (obj, prefix) => {
  const str = []
  let property

  for (property in obj) {
    if (obj.hasOwnProperty(property)) {
      var key = prefix ? prefix + "[" + property + "]" : property,
        value = obj[property]
      str.push(
        value !== null && typeof value === "object"
          ? serialize(value, key)
          : encodeURIComponent(key) + "=" + encodeURIComponent(value)
      )
    }
  }
  return str.join("&")
}

export const isBrowser = typeof window !== "undefined"

export const setLocalStorage = (item, value) => {
  if (!isBrowser) {
    return
  }
  window.localStorage.setItem(item, value)
}

export const getLocalStorage = (item) => {
  if (!isBrowser) {
    return
  }
  return window.localStorage.getItem(item)
}
