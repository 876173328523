import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import tw, { styled } from "twin.macro"


const Image = styled(Img)`
  ${tw`absolute inset-0 w-full h-full object-cover`}
`

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "work-computers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Image fluid={data.placeholderImage.childImageSharp.fluid} alt="biurko z komputerem i notatkami" />
}

export default HeroImage
