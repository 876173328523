import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

import { getLocalStorage, setLocalStorage } from "../utils"

const Container = styled.div`
  ${tw`bg-white shadow-xl rounded-md fixed left-3 bottom-3 text-sm w-64 text-gray-500`}
`

const InnerContainer = styled.div`
  ${tw`shadow rounded-md`}
`

const Wrapper = styled.div`
  ${tw`shadow-2xl p-3 rounded-md`}
`

const Button = styled.button`
  ${tw`text-black py-1 px-2 mt-4 bg-gray-200 text-sm rounded-md`}
`

const MoreLink = styled(Link)`
  ${tw`underline text-black`}
`

const CookiesBanner = () => {
  const [cookieAccepted, setCookieAccepted] = React.useState(false)
  React.useEffect(() => {
    const cookie = getLocalStorage("cookie")
    if (cookie === "accepted") {
      setCookieAccepted(true)
    }
  }, [])
  return !cookieAccepted ? (
    <Container>
      <InnerContainer>
        <Wrapper>
          Na naszej stronie stosujemy Cookies w celach analitycznych. Możesz
          wyłączyć je w ustawieniach swojej przeglądarki -{" "}
          <MoreLink to="/polityka-cookies">więcej</MoreLink>.
          <div>
            <Button
              onClick={() => {
                setLocalStorage("cookie", "accepted")
                setCookieAccepted(true)
              }}
            >
              Akceptuję
            </Button>
          </div>
        </Wrapper>
      </InnerContainer>
    </Container>
  ) : null
}

export default CookiesBanner
