import React from "react"
import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

import Logo from "./Logo"
import Shevron from "./Shevron"
import MenuIcon from './MenuIcon'

const HeroInnerContainer = styled.div`
  ${tw`top-0 bg-white`};
  z-index: 2;
`

const HeroWrapper = styled.div`
  ${tw`max-w-7xl mx-auto px-4 sm:px-6`}
`

const NavigationContainer = styled.div`
  ${tw`flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10`}
`

const NavigationItem = styled(Link)`
  ${tw`text-gray-600 inline-flex items-center md:space-x-2 md:text-base leading-9 md:leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 px-4 md:px-2 mx-0`}
  ${props => props.hideOnMobile && tw`hidden md:flex`}
`

const NavigationItemsContainer = styled.nav`
  ${tw`hidden md:flex space-x-0 md:space-x-10 absolute left-0 md:left-auto md:relative bottom-0 top-28 md:top-0 flex-col md:flex-row bg-white md:bg-transparent rounded-lg shadow-lg md:shadow-none z-50 px-4 md:px-0`};
  ${props => props.show && tw`flex`}
`

const SubmenuContainer = styled.div`
  ${tw`md:hidden md:absolute md:-ml-4 pt-3 md:transform md:px-2 md:w-screen md:max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2`}
`

const NavigationItemContainer = styled.div`
  ${tw`relative`}

  &:hover {
    ${SubmenuContainer} {
      ${tw`block`}
    }
  }
`

const LogoContainer = styled.div`
  ${tw`lg:w-0 lg:flex-1`}
`

const LogoLink = styled(Link)`
  ${tw`flex`}
`

const SubmenuInnerContainer = styled.div`
  ${tw`md:rounded-lg md:shadow-lg`}
`

const SubmenuWrapper = styled.div`
  ${tw`md:rounded-lg md:shadow-lg overflow-hidden`}
`

const SubmenuItemsWrapper = styled.div`
  ${tw`z-20 relative grid md:gap-6 bg-white px-2 md:px-5 md:py-6 sm:gap-8 sm:p-8`}
`

const SumbenuLink = styled(Link)`
  ${tw`p-2 md:-m-3 md:p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150`}
`

const SumbenuItem = styled.div`
  ${tw`space-y-1`}
`

const SubmenuItemTitle = styled.p`
  ${tw`text-base leading-6 font-medium text-gray-900`}
`

const SubmenuItemDescription = styled.p`
  ${tw`text-sm leading-5 text-gray-500`}
`

const MobileMenu = styled.button`
  ${tw`border-transparent md:hidden flex justify-between items-center`}
`

const Navigation = () => {
  const [showMobileMenu, setShowMobileMenu] = React.useState(false)
  return (
    <HeroInnerContainer>
      <HeroWrapper>
        <NavigationContainer>
          <LogoContainer>
            <LogoLink to="/">
              <Logo />
            </LogoLink>
          </LogoContainer>
          <MobileMenu onClick={() => {
            setShowMobileMenu(!showMobileMenu)
          }}>
            <MenuIcon />
          </MobileMenu>
          <NavigationItemsContainer show={showMobileMenu}>
            <NavigationItemContainer>
              <NavigationItem hideOnMobile className="group" to="/">
                <span>Kursy księgowości</span>
                <Shevron />
              </NavigationItem>
              <SubmenuContainer>
                <SubmenuInnerContainer>
                  <SubmenuWrapper>
                    <SubmenuItemsWrapper>
                      <SumbenuLink to="/kursy-ksiegowosci">
                        <SumbenuItem>
                          <SubmenuItemTitle>Od podstaw</SubmenuItemTitle>
                          <SubmenuItemDescription>
                            Nie masz doświadczenia w księgowości? Wyjaśnimy
                            wszystko od postaw!
                          </SubmenuItemDescription>
                        </SumbenuItem>
                      </SumbenuLink>
                      <SumbenuLink to="/kurs-samodzielny-ksiegowy">
                        <SumbenuItem>
                          <SubmenuItemTitle>
                            Dla zaawansowanych
                          </SubmenuItemTitle>
                          <SubmenuItemDescription>
                            Dla chcących zostać głównymi księgowymi
                          </SubmenuItemDescription>
                        </SumbenuItem>
                      </SumbenuLink>
                      <SumbenuLink to="/kurs-ksiegowosci-malych-firm">
                        <SumbenuItem>
                          <SubmenuItemTitle>Małych firm</SubmenuItemTitle>
                          <SubmenuItemDescription>
                            Sam prowadź księgowość swojej firmy
                          </SubmenuItemDescription>
                        </SumbenuItem>
                      </SumbenuLink>
                    </SubmenuItemsWrapper>
                  </SubmenuWrapper>
                </SubmenuInnerContainer>
              </SubmenuContainer>
            </NavigationItemContainer>
            <NavigationItem to="/posterus-wroclaw-kursy-ksiegowosci-rachunkowosci-i-kadrowo-placowe">O nas</NavigationItem>
            <NavigationItem to="/kontakt">Kontakt</NavigationItem>
          </NavigationItemsContainer>
        </NavigationContainer>
      </HeroWrapper>
    </HeroInnerContainer>
  )
}

export default Navigation
