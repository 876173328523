import React from "react"
import tw, { styled } from "twin.macro"

const Svg = styled.svg`
  ${tw`h-9 md:h-12`}
`

const Logo = () => {
  return (
    <Svg
      height="48px"
      viewBox="0 0 258 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="description"
    >
      <title id="description">Kursyfinansowe logo</title>
      <defs>
        <polygon
          id="path-1"
          points="59.104 30.32 59.104 22.712 64.864 30.32 69.832 30.32 62.992 21.656 69.832 13.352 64.912 13.352 59.104 20.816 59.104 13.352 55 13.352 55 30.32"
        ></polygon>
        <path
          d="M76.384,30.464 C77.392,30.464 78.276,30.232 79.036,29.768 C79.796,29.304 80.368,28.68 80.752,27.896 L80.752,27.896 L80.752,30.32 L84.88,30.32 L84.88,16.928 L80.752,16.928 L80.752,24.032 C80.752,25.008 80.512,25.76 80.032,26.288 C79.552,26.816 78.896,27.08 78.064,27.08 C77.264,27.08 76.628,26.828 76.156,26.324 C75.684,25.82 75.448,25.112 75.448,24.2 L75.448,24.2 L75.448,16.928 L71.368,16.928 L71.368,24.704 C71.368,26.496 71.82,27.904 72.724,28.928 C73.628,29.952 74.848,30.464 76.384,30.464 Z"
          id="path-2"
        ></path>
        <path
          d="M91.816,30.32 L91.816,24.224 C91.816,23.12 92.116,22.336 92.716,21.872 C93.316,21.408 94.16,21.176 95.248,21.176 L95.248,21.176 L96.424,21.176 L96.424,16.784 C95.48,16.784 94.604,17.024 93.796,17.504 C92.988,17.984 92.328,18.632 91.816,19.448 L91.816,19.448 L91.816,16.928 L87.712,16.928 L87.712,30.32 L91.816,30.32 Z"
          id="path-3"
        ></path>
        <path
          d="M103.984,30.488 C105.056,30.488 105.984,30.32 106.768,29.984 C107.552,29.648 108.152,29.184 108.568,28.592 C108.984,28 109.192,27.32 109.192,26.552 C109.192,25.608 108.952,24.856 108.472,24.296 C107.992,23.736 107.424,23.332 106.768,23.084 C106.112,22.836 105.272,22.592 104.248,22.352 C103.304,22.144 102.612,21.936 102.172,21.728 C101.732,21.52 101.512,21.208 101.512,20.792 C101.512,20.424 101.632,20.14 101.872,19.94 C102.112,19.74 102.464,19.64 102.928,19.64 C103.52,19.64 104.012,19.796 104.404,20.108 C104.796,20.42 105.048,20.848 105.16,21.392 L105.16,21.392 L109,21.392 C108.744,20 108.108,18.88 107.092,18.032 C106.076,17.184 104.72,16.76 103.024,16.76 C101.36,16.76 100.072,17.132 99.16,17.876 C98.248,18.62 97.792,19.592 97.792,20.792 C97.792,21.72 98.024,22.452 98.488,22.988 C98.952,23.524 99.504,23.912 100.144,24.152 C100.784,24.392 101.6,24.616 102.592,24.824 C103.584,25.048 104.3,25.26 104.74,25.46 C105.18,25.66 105.4,25.992 105.4,26.456 C105.4,26.808 105.28,27.092 105.04,27.308 C104.8,27.524 104.448,27.632 103.984,27.632 C103.312,27.632 102.764,27.48 102.34,27.176 C101.916,26.872 101.672,26.432 101.608,25.856 L101.608,25.856 L97.648,25.856 C97.888,27.344 98.552,28.488 99.64,29.288 C100.728,30.088 102.176,30.488 103.984,30.488 Z"
          id="path-4"
        ></path>
        <polygon
          id="path-5"
          points="116.968 36.704 125.32 16.928 120.784 16.928 117.784 25.136 114.568 16.928 109.984 16.928 115.6 29.816 112.456 36.704"
        ></polygon>
        <polygon
          id="path-6"
          points="130.792 30.32 130.792 23.456 136.168 23.456 136.168 20.336 130.792 20.336 130.792 16.616 137.752 16.616 137.752 13.352 126.688 13.352 126.688 30.32"
        ></polygon>
        <path
          d="M141.928,15.296 C142.664,15.296 143.256,15.092 143.704,14.684 C144.152,14.276 144.376,13.768 144.376,13.16 C144.376,12.536 144.152,12.02 143.704,11.612 C143.256,11.204 142.664,11 141.928,11 C141.192,11 140.6,11.204 140.152,11.612 C139.704,12.02 139.48,12.536 139.48,13.16 C139.48,13.768 139.704,14.276 140.152,14.684 C140.6,15.092 141.192,15.296 141.928,15.296 Z"
          id="path-7"
        ></path>
        <polygon
          id="path-8"
          points="143.968 30.32 143.968 16.928 139.864 16.928 139.864 30.32"
        ></polygon>
        <path
          d="M150.904,30.32 L150.904,23.216 C150.904,22.24 151.148,21.488 151.636,20.96 C152.124,20.432 152.784,20.168 153.616,20.168 C154.4,20.168 155.028,20.42 155.5,20.924 C155.972,21.428 156.208,22.128 156.208,23.024 L156.208,23.024 L156.208,30.32 L160.312,30.32 L160.312,22.544 C160.312,20.752 159.86,19.344 158.956,18.32 C158.052,17.296 156.832,16.784 155.296,16.784 C154.272,16.784 153.38,17.016 152.62,17.48 C151.86,17.944 151.288,18.576 150.904,19.376 L150.904,19.376 L150.904,16.928 L146.8,16.928 L146.8,30.32 L150.904,30.32 Z"
          id="path-9"
        ></path>
        <path
          d="M168.112,16.76 C169.136,16.76 170.016,16.992 170.752,17.456 C171.488,17.92 172.032,18.552 172.384,19.352 L172.384,19.352 L172.384,16.928 L176.464,16.928 L176.464,30.32 L172.384,30.32 L172.384,27.896 L172.27264,28.13096 C171.92128,28.82072 171.4144,29.3744 170.752,29.792 C170.016,30.256 169.136,30.488 168.112,30.488 C167.008,30.488 166.02,30.212 165.148,29.66 C164.276,29.108 163.588,28.312 163.084,27.272 C162.58,26.232 162.328,25.016 162.328,23.624 C162.328,22.216 162.58,20.996 163.084,19.964 C163.588,18.932 164.276,18.14 165.148,17.588 C166.02,17.036 167.008,16.76 168.112,16.76 Z M169.432,20.36 C168.552,20.36 167.844,20.648 167.308,21.224 C166.772,21.8 166.504,22.6 166.504,23.624 C166.504,24.648 166.772,25.448 167.308,26.024 C167.844,26.6 168.552,26.888 169.432,26.888 C170.296,26.888 171.004,26.592 171.556,26 C172.108,25.408 172.384,24.616 172.384,23.624 C172.384,22.616 172.108,21.82 171.556,21.236 C171.004,20.652 170.296,20.36 169.432,20.36 Z"
          id="path-10"
        ></path>
        <path
          d="M183.4,30.32 L183.4,23.216 C183.4,22.24 183.644,21.488 184.132,20.96 C184.62,20.432 185.28,20.168 186.112,20.168 C186.896,20.168 187.524,20.42 187.996,20.924 C188.468,21.428 188.704,22.128 188.704,23.024 L188.704,23.024 L188.704,30.32 L192.808,30.32 L192.808,22.544 C192.808,20.752 192.356,19.344 191.452,18.32 C190.548,17.296 189.328,16.784 187.792,16.784 C186.768,16.784 185.876,17.016 185.116,17.48 C184.356,17.944 183.784,18.576 183.4,19.376 L183.4,19.376 L183.4,16.928 L179.296,16.928 L179.296,30.32 L183.4,30.32 Z"
          id="path-11"
        ></path>
        <path
          d="M201.352,30.488 C202.424,30.488 203.352,30.32 204.136,29.984 C204.92,29.648 205.52,29.184 205.936,28.592 C206.352,28 206.56,27.32 206.56,26.552 C206.56,25.608 206.32,24.856 205.84,24.296 C205.36,23.736 204.792,23.332 204.136,23.084 C203.48,22.836 202.64,22.592 201.616,22.352 C200.672,22.144 199.98,21.936 199.54,21.728 C199.1,21.52 198.88,21.208 198.88,20.792 C198.88,20.424 199,20.14 199.24,19.94 C199.48,19.74 199.832,19.64 200.296,19.64 C200.888,19.64 201.38,19.796 201.772,20.108 C202.164,20.42 202.416,20.848 202.528,21.392 L202.528,21.392 L206.368,21.392 C206.112,20 205.476,18.88 204.46,18.032 C203.444,17.184 202.088,16.76 200.392,16.76 C198.728,16.76 197.44,17.132 196.528,17.876 C195.616,18.62 195.16,19.592 195.16,20.792 C195.16,21.72 195.392,22.452 195.856,22.988 C196.32,23.524 196.872,23.912 197.512,24.152 C198.152,24.392 198.968,24.616 199.96,24.824 C200.952,25.048 201.668,25.26 202.108,25.46 C202.548,25.66 202.768,25.992 202.768,26.456 C202.768,26.808 202.648,27.092 202.408,27.308 C202.168,27.524 201.816,27.632 201.352,27.632 C200.68,27.632 200.132,27.48 199.708,27.176 C199.284,26.872 199.04,26.432 198.976,25.856 L198.976,25.856 L195.016,25.856 C195.256,27.344 195.92,28.488 197.008,29.288 C198.096,30.088 199.544,30.488 201.352,30.488 Z"
          id="path-12"
        ></path>
        <path
          d="M215.104,16.76 C216.432,16.76 217.62,17.036 218.668,17.588 C219.716,18.14 220.54,18.936 221.14,19.976 C221.74,21.016 222.04,22.232 222.04,23.624 C222.04,25.016 221.74,26.232 221.14,27.272 C220.54,28.312 219.716,29.108 218.668,29.66 C217.62,30.212 216.432,30.488 215.104,30.488 C213.776,30.488 212.584,30.212 211.528,29.66 C210.472,29.108 209.644,28.312 209.044,27.272 C208.444,26.232 208.144,25.016 208.144,23.624 C208.144,22.232 208.444,21.016 209.044,19.976 C209.644,18.936 210.472,18.14 211.528,17.588 C212.584,17.036 213.776,16.76 215.104,16.76 Z M215.104,20.312 C214.32,20.312 213.66,20.596 213.124,21.164 C212.588,21.732 212.32,22.552 212.32,23.624 C212.32,24.696 212.588,25.512 213.124,26.072 C213.66,26.632 214.32,26.912 215.104,26.912 C215.888,26.912 216.544,26.632 217.072,26.072 C217.6,25.512 217.864,24.696 217.864,23.624 C217.864,22.552 217.6,21.732 217.072,21.164 C216.544,20.596 215.888,20.312 215.104,20.312 Z"
          id="path-13"
        ></path>
        <polygon
          id="path-14"
          points="230.872 30.32 233.32 21.344 235.744 30.32 240.328 30.32 243.76 16.928 239.944 16.928 238.168 26.6 235.6 16.928 231.232 16.928 228.736 26.576 226.96 16.928 222.856 16.928 226.288 30.32"
        ></polygon>
        <path
          d="M251.296,16.76 C252.624,16.76 253.792,17.032 254.8,17.576 C255.808,18.12 256.588,18.884 257.14,19.868 C257.692,20.852 257.968,21.984 257.968,23.264 C257.968,23.6 257.928,23.968 257.848,24.368 L257.848,24.368 L248.56,24.368 L248.576333,24.6140833 C248.652111,25.4946944 248.902667,26.1366667 249.328,26.54 C249.792,26.98 250.384,27.2 251.104,27.2 C251.712,27.2 252.216,27.048 252.616,26.744 C253.016,26.44 253.28,26.048 253.408,25.568 L253.408,25.568 L257.752,25.568 L257.68563,25.878963 C257.488099,26.698321 257.126222,27.4386667 256.6,28.1 C256.008,28.844 255.256,29.428 254.344,29.852 C253.432,30.276 252.416,30.488 251.296,30.488 C249.984,30.488 248.82,30.212 247.804,29.66 C246.788,29.108 245.992,28.312 245.416,27.272 C244.84,26.232 244.552,25.016 244.552,23.624 C244.552,22.216 244.836,20.996 245.404,19.964 C245.972,18.932 246.768,18.14 247.792,17.588 C248.816,17.036 249.984,16.76 251.296,16.76 Z M251.296,20 C250.544,20 249.92,20.224 249.424,20.672 C248.928,21.12 248.648,21.776 248.584,22.64 L248.584,22.64 L253.816,22.64 C253.832,21.76 253.596,21.1 253.108,20.66 C252.62,20.22 252.016,20 251.296,20 Z"
          id="path-15"
        ></path>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="course" transform="translate(-30.000000, -53.000000)">
          <g id="logo" transform="translate(30.000000, 53.000000)">
            <rect
              id="rect2985"
              fill="#1a57db"
              x="-3.87245791e-13"
              y="5.68434189e-14"
              width="47.1943264"
              height="47.1943264"
              rx="3.39411248"
            ></rect>
            <path
              d="M31.9935122,13.4144502 C31.995279,16.4471262 30.3783802,19.2501907 27.7522998,20.7670389 C25.1262195,22.2838872 21.8902449,22.2838872 19.2641645,20.7670389 C16.6380841,19.2501907 15.0211854,16.4471262 15.0229522,13.4144502 C15.0211854,10.3817742 16.6380841,7.57870983 19.2641645,6.06186156 C21.8902449,4.54501329 25.1262195,4.54501329 27.7522998,6.06186156 C30.3783802,7.57870983 31.995279,10.3817742 31.9935122,13.4144502 L31.9935122,13.4144502 Z"
              id="path3812"
              stroke="#1a57db"
              strokeWidth="1.6"
              fill="#FFFFFF"
            ></path>
            <polygon
              id="rect3765"
              stroke="#1a57db"
              strokeWidth="1.28"
              fill="#FFFFFF"
              points="8.794288 17.5612528 23.474944 20.949096 23.474944 44.7028928 8.794288 41.3150496"
            ></polygon>
            <path
              d="M39.094912,17.5612528 C34.094704,17.5527248 29.099104,17.5936768 24.414256,20.949096 L24.414256,44.7028928 C29.19064,40.9321104 34.074512,39.5844192 39.094912,41.3150496 L39.094912,17.5612528 Z"
              id="path3768"
              stroke="#1a57db"
              strokeWidth="1.28"
              fill="#FFFFFF"
            ></path>
            <path
              d="M38.621824,23.3096624 C37.478608,23.3096624 36.558288,23.7642064 36.558288,24.3295248 C36.558288,24.5746768 36.733312,24.8007888 37.02256,24.9763648 C36.733312,25.1519392 36.558288,25.3780512 36.558288,25.6232032 C36.558288,25.8834192 36.75616,26.1179424 37.078128,26.2978192 C36.7568,26.4776256 36.558288,26.7125088 36.558288,26.9724384 C36.558288,27.5377552 37.478608,27.9923008 38.621824,27.9923008 C39.765008,27.9923008 40.68536,27.5377552 40.68536,26.9724384 C40.685344,26.7127936 40.490176,26.4775536 40.169456,26.2978192 C40.4908,26.118016 40.68536,25.8831296 40.68536,25.6232032 C40.685344,25.3772352 40.512048,25.1521968 40.221056,24.9763648 C40.512048,24.8005312 40.68536,24.5754928 40.68536,24.3295248 C40.685344,23.7642064 39.765008,23.3096624 38.621824,23.3096624 L38.621824,23.3096624 Z"
              id="rect3818"
              fill="#FFFFFF"
            ></path>
            <path
              d="M7.673584,24.823592 C7.673584,25.0687424 7.848624,25.294856 8.137872,25.4704304 C7.848624,25.6460048 7.673584,25.8721168 7.673584,26.1172688 C7.673584,26.3774848 7.871456,26.612008 8.19344,26.7918864 C7.872096,26.9716912 7.673584,27.2065744 7.673584,27.466504 C7.673584,28.0318224 8.59392,28.4863664 9.73712,28.4863664 C10.88032,28.4863664 11.800656,28.0318224 11.800656,27.466504 C11.800656,27.2068592 11.605488,26.9716192 11.284768,26.7918864 C11.606096,26.6120816 11.800656,26.3771968 11.800656,26.1172688 C11.800656,25.8713024 11.62736,25.6462624 11.336352,25.4704304 C11.62736,25.2945984 11.800656,25.0695584 11.800656,24.823592 C11.800656,24.258272 10.88032,23.8037296 9.73712,23.8037296 C8.59392,23.8037296 7.673584,24.258272 7.673584,24.823592 Z"
              id="path3832"
              fill="#FFFFFF"
            ></path>
            <polygon
              id="rect3846"
              fill="#FFFFFF"
              points="18.281184 5.1684192 37.446896 5.1684192 28.621744 9.6255632 9.456032 9.6255632"
            ></polygon>
            <polygon
              id="path3849"
              fill="#FFFFFF"
              points="35.670368 6.0512464 37.604912 12.443224 35.996064 12.6172272 34.077424 5.764824"
            ></polygon>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-1"></use>
              <use xlinkHref="#path-1"></use>
            </g>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-2"></use>
              <use xlinkHref="#path-2"></use>
            </g>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-3"></use>
              <use xlinkHref="#path-3"></use>
            </g>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-4"></use>
              <use xlinkHref="#path-4"></use>
            </g>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-5"></use>
              <use xlinkHref="#path-5"></use>
            </g>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-6"></use>
              <use xlinkHref="#path-6"></use>
            </g>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-7"></use>
              <use xlinkHref="#path-7"></use>
            </g>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-8"></use>
              <use xlinkHref="#path-8"></use>
            </g>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-9"></use>
              <use xlinkHref="#path-9"></use>
            </g>
            <g id="Combined-Shape" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-10"></use>
              <use xlinkHref="#path-10"></use>
            </g>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-11"></use>
              <use xlinkHref="#path-11"></use>
            </g>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-12"></use>
              <use xlinkHref="#path-12"></use>
            </g>
            <g id="Combined-Shape" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-13"></use>
              <use xlinkHref="#path-13"></use>
            </g>
            <g id="Path" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-14"></use>
              <use xlinkHref="#path-14"></use>
            </g>
            <g id="Combined-Shape" fillRule="nonzero" fill="#000000">
              <use xlinkHref="#path-15"></use>
              <use xlinkHref="#path-15"></use>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}

export default Logo
