import React from "react"
import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

import Logo from "./Logo"

const Container = styled.footer`
  ${tw`bg-white pb-4`}
`

const InnerContainer = styled.div`
  ${tw`max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8`}
`

const ContentContainer = styled.div`
  ${tw`xl:grid xl:grid-cols-3 xl:gap-8`}
`

const LeftSide = styled.div`
  ${tw`space-y-8 xl:col-span-1`}
`

const RightSide = styled.div`
  ${tw`mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2`}
`

const LinkContainer = styled.div`
  ${tw`md:grid md:grid-cols-2 md:gap-8`}
`

const LinkHeader = styled.h4`
  ${tw`text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase`}
`

const LinkList = styled.ul`
  ${tw`mt-4 space-y-4`}
`

const LinkLink = styled(Link)`
  ${tw`text-base leading-6 text-gray-500 hover:text-gray-900`}
`

const BottonContainer = styled.div`
  ${tw`mt-12 border-t border-gray-200 pt-8`}
`

const BottomText = styled.p`
  ${tw`text-base leading-6 text-gray-400 mb-4`}
`

const BottomSmallText = styled.p`
  ${tw`leading-5 text-sm text-gray-400`}
`

const Footer = () => {
  return (
    <Container>
      <InnerContainer>
        <ContentContainer>
          <LeftSide>
            <Logo />
          </LeftSide>
          <RightSide>
            <LinkContainer>
              <div>
                <LinkHeader>Kursy</LinkHeader>
                <LinkList>
                  <li>
                    <LinkLink to="/kursy-ksiegowosci">
                      Podstawy księgowości
                    </LinkLink>
                  </li>
                  <li>
                    <LinkLink to="/kurs-samodzielny-ksiegowy">
                      Księgowość dla zaawansowanych
                    </LinkLink>
                  </li>
                  <li>
                    <LinkLink to="/kurs-ksiegowosci-malych-firm">
                      Księgowość małych firm
                    </LinkLink>
                  </li>
                </LinkList>
              </div>
              <div>
                <LinkHeader>O firmie</LinkHeader>
                <LinkList>
                  <li>
                    <LinkLink to="/posterus-wroclaw-kursy-ksiegowosci-rachunkowosci-i-kadrowo-placowe">
                      O nas
                    </LinkLink>
                  </li>
                  <li>
                    <LinkLink to="/nasi-wykladowcy">Nasi wykładowcy</LinkLink>
                  </li>
                  <li>
                    <LinkLink to="/lokalizacja">Lokalizacja</LinkLink>
                  </li>
                </LinkList>
              </div>
            </LinkContainer>
            <LinkContainer>
              <div>
                <LinkHeader>Kontakt</LinkHeader>
                <LinkList>
                  <li>
                    <LinkLink to="/kontakt">Napisz do nas</LinkLink>
                  </li>
                </LinkList>
              </div>
              <div>
                <LinkHeader>Polityki</LinkHeader>
                <LinkList>
                  <li>
                    <LinkLink to="/polityka-cookies">Polityka cookies</LinkLink>
                  </li>
                  <li>
                    <LinkLink to="/polityka-prywatnosci">
                      Polityka prywatności
                    </LinkLink>
                  </li>
                  <li>
                    <LinkLink to="/regulamin-szkolen">Regulamin</LinkLink>
                  </li>
                </LinkList>
              </div>
            </LinkContainer>
          </RightSide>
        </ContentContainer>
        <BottonContainer>
          <BottomText>
            &copy; 2024 Posterus, wszystkie prawa zastrzeżone.
          </BottomText>
          <BottomSmallText>
            Posterus: ul. Polna 1c/3, 55-010 Święta Katarzyna <br />
            NIP 8941732722, Regon 021317284
            <br />
            Konto bankowe: ING Bank 96 1050 1575 1000 0091 4035 2346
            <br />
            Posiadamy wpis do Rejestru Instytucji Szkoleniowych nr
            2.02/00126/2013
          </BottomSmallText>
        </BottonContainer>
      </InnerContainer>
    </Container>
  )
}

export default Footer
