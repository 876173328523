import React from "react"
import tw, { styled } from "twin.macro"

const Icon = styled.svg`
  ${tw`h-8 w-8 text-blue-700`}
`

const MenuIcon = () => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
  </Icon>
)

export default MenuIcon
