import React from "react"
import tw, { styled } from "twin.macro"
import HeroImage from './HeroImage'

const MainContent = styled.main`
  ${tw`lg:relative`}
`

const ContentTextWrapper = styled.div`
  ${tw`mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left`}
`

const ContentTextInnerWrapper = styled.div`
  ${tw`px-4 lg:w-1/2 sm:px-8 xl:pr-16`}
`

const MainHeader = styled.h2`
  ${tw`text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl`}
`

const HeroText = styled.p`
  ${tw`mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl`}
`

const HeroButtonsContainer = styled.div`
  ${tw`mt-10 sm:flex sm:justify-center lg:justify-start`}
`

const ImageContainer = styled.div`
  ${tw`relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full`}
`

const Highlight = styled.span`
  ${tw`text-blue-700`}
`

const HeroBanner = () => {
    return (
        <MainContent>
          <ContentTextWrapper>
            <ContentTextInnerWrapper>
              <MainHeader>Szkolimy przyszłych <Highlight>księgowych</Highlight></MainHeader>
              <HeroText>
                Indywidualne kursy księgowości we <strong>Wrocławiu</strong>.
              </HeroText>
              <HeroButtonsContainer>

              </HeroButtonsContainer>
            </ContentTextInnerWrapper>
          </ContentTextWrapper>
          <ImageContainer>
            <HeroImage/>
          </ImageContainer>
        </MainContent>
    )
}

export default HeroBanner
