import { isBrowser } from "./utils"


export const initLiveChat = () => {
  if (!isBrowser) {
    return
  }
  window.__ow = window.__ow || {};
  window.__ow.organizationId = "d843d8d8-b331-4360-b0ee-9ade44222251";
  ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[OpenWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.openwidget.com/openwidget.js",t.head.appendChild(n)}};!n.__ow.asyncInit&&e.init(),n.OpenWidget=n.OpenWidget||e}(window,document,[].slice)) // eslint-disable-line
}

export const setName = name => {
  if (!isBrowser) {
    return
  }
  window.OpenWidget.call("set_customer_name", name)
}

export const setEmail = email => {
  if (!isBrowser) {
    return
  }
  window.OpenWidget.call("set_customer_email", email)
}

export const triggerGoal = () => {
  if (!isBrowser) {
    return
  }
  window.OpenWidget.call("update_session_variables", {
    __sales_tracker_KcmbUeRo9eMIYyIynT8IMJeZiB4GuB9P: "1",
    __order_price: "999",
  })
}

export const maximize = () => {
  if (!isBrowser) {
    return
  }
  window.OpenWidget.call("maximize")
}
